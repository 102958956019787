import React from "react";
import Company from './Company/Company';

const companies = (props) => (
    props.companies.map((company, index) => 
      <Company 
        company={company} 
        click={() => props.clicked(index)}
        key={company.compKey}
        length={props.companies.length}
        index={index}
      />
    )
  );

export default companies
