import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { NavigationBar } from './NavigationBar';
import Home from '../components/Pages/Home';
// import About from "../components/Pages/About";
// import Tali from "../components/Pages/Tali";
import Contact from '../components/Pages/Contact';
import Investments from '../components/Pages/Investments';
import { Layout } from './Layout';
import NotFound from '../components/NotFound';
import Footer from '../components/Footer/Footer';

// import ComingSoon from "./ComingSoon";
// import './App.css';

class App extends Component {
  state = {
    page: 'main',
    loading: true,
  };

  render() {
    return (
      <React.Fragment>
        <Router>
          <NavigationBar />
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              {/* <Route path="/about" component={About} /> */}
              <Route path="/investments" component={Investments} />
              {/* <Route path="/contact" component={Contact} /> */}
              {/* <Route path="/tali" component={Tali} /> */}
              <Route component={NotFound} />
            </Switch>
          </Layout>
        </Router>
        <Footer />
      </React.Fragment>
    );
  }
}

export default App;
