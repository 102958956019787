const CompaniesList = [
  {
    "name": "Fluence Corporation",
    "compKey": "Fluence",
    "link": "https://www.fluencecorp.com",
    "mark": "ASX: FLC",
    "logo": "/images/fluencelogo.svg",
    "shortText": "",
    "description": "Fluence offers an integrated range of services across the complete water cycle, from early-stage evaluation, through design and delivery, to ongoing support and optimization of water-related assets. With core operations in North America, South America, the Middle East, and Europe, Fluence is now turning its sights to expanding into the vast Chinese market for rural wastewater treatment. Fluence brings together breakthrough water-treatment technologies and proven delivery platforms to optimize the water cycle for the 21st century. We provide the middle market with local, sustainable, and fast-to-deploy water and wastewater treatment and reuse solutions, empowering businesses and communities worldwide to make the most of their water resources.",
    "images": []
  },
  {
    "name": "Dental SmartMirror Ltd.",
    "compKey": "SmartMirror",
    "mark": "",
    "logo": "/images/image4.impress.ly.png",
    "link": "http://www.smartmirror.dental",
    "shortText": "Dental SMARTmirror Changes Everything",
    "description": "The dental mirror has not changed in 100 years – until now. SmartMirror is the world’s first smart dental mirror and it changes everything.",
    "images": []
  },
  {
    "name": "Sensera Ltd.",
    "compKey": "Sensera",
    "mark": "ASX: SE1",
    "logo": "/images/Sensera_pos_4c.svg",
    "link": "http://www.sensera.com",
    "shortText": "",
    "description": "Sensera Ltd. is an Internet of Things (IOT) solution provider that delivers Sensor based products transforming real-time data into meaningful information, action and value. The company designs and manufactures hardware and software across the vertical technology spectrum from unique structures as MicroElectroMechanical Systems (MEMS) and sensors, as well as wireless networked systems and software that when combined, drive an entire IOT platform solution.",
    "images": []
  },
  {
    "name": "Respiri Ltd.",
    "compKey": "Respiri",
    "mark": "ASX: RSH",
    "logo": "/images/respiri.png",
    "link": "http://www.respiri.co",
    "shortText": "",
    "description": "A Breath of Fresh Ideas. Respiri Limited is a health technology company leading the way in the development of innovative devices and mobile health apps to improve the management of chronic and costly respiratory disorders such as asthma and COPD. Building on decades of experience in the research and development of cutting-edge clinical products for hospitals, the company has first-mover advantage in providing broad access to its proprietary acoustic based clinical solutions for remote monitoring with the development of a suite of over-the-counter connected devices. Health authorities universally agree that mHealth solutions can transform asthma care and health conscious consumers are rapidly embracing patient self-management with the aid of smartphones, the growth engine for Respiri’s flagship product, Wheezo. With the addition of new products, including a connected device for nocturnal monitoring in development, Respiri has a captive market, globally, of parents and carers of young children who cannot perform lung function tests. Respiri products have been cleared for use by the US Food and Drug Administration, the European Union CE, the Australian TGA. AirSonea Gen II is planned for launch in 2019.",
    "images": []
  },
  {
    "name": "Aduro",
    "compKey": "Aduro",
    "mark": "NASDAQ: ADRO",
    "logo": "/images/aduro.png",
    "link": "https://www.aduro.com",
    "shortText": "Aduro is dedicated to its mission to discover, develop, and commercialize immunotherapies that transform the treatment of challenging diseases, including cancer.",
    "description": "To fulfill its mission, the company is advancing several leading-edge proprietary technologies that are designed to initiate and sustain the immune system to mobilize and attack tumors. Collectively, Aduro’s technologies are uniquely positioned to recruit and direct the immune system by activating cancer-fighting immune cells and inhibiting immune suppressive cells known to allow tumor growth. Product candidates from Aduro’s STING Pathway Activators and B-select monoclonal antibody technologies are designed to stimulate and/or regulate innate and adaptive immune responses, either as single agents or in combination with conventional therapies (chemotherapy and radiation) as well as other cutting-edge immunotherapies. This diverse set of technologies has led to a strong pipeline of clinical and preclinical candidates, which are being evaluated in a number of cancer indications. Additionally, Aduro’s technologies have the potential to generate product candidates that address other therapeutic areas, such as autoimmune and infectious diseases. Aduro collaborates and partners with leading academic investigators, along with pharmaceutical and biotechnology companies in an effort to develop its technologies in an expeditious manner and make new therapies available to patients in need.",
    "images": []
  },
  {
    "name": "Massivit Ltd.",
    "compKey": "Massivit",
    "mark": "",
    "logo": "/images/massivit.png",
    "link": "https://www.massivit3d.com",
    "shortText": "BRINGING INSPIRATION TO LIFE",
    "description": "Massivit 3D Printing Technologies Ltd. is a pioneer of large format 3D printing solutions for visual communication spanning the advertising, retail, events, entertainment, interior design and architectural sectors. Leveraging its proprietary Gel Dispensing Printing (GDP) technology, the company’s solutions enable rapid and cost-effective production of super-sized, eye-catching 3D signage, displays and props. Massivit 3D’s mission is to enable print services and other visual communication producers to expand their business offering, gain a competitive edge, and generate new sources of revenue. Founded in 2013 by a team of recognized industry experts, Massivit 3D is headquartered in Lod, Israel and provides its worldwide community with end-to-end services supported through an extensive dealer network.",
    "images": []
  },
  {
    "name": "NRGene Ltd.",
    "compKey": "NRGene",
    "mark": "",
    "logo": "/images/PNG-LOGO-02.png",
    "link": "https://www.nrgene.com",
    "shortText": "",
    "description": "NRGene is a bio-informatica company that had the best and most efficient tool for animal and plant genome analysis. With global food demand constantly on the rise and with ever changing climate and environmental conditions, we must learn to grow more for less. Our turn-key big-data solutions offer breeders the opportunity to maximize their agricultural yield, achieving better results in record time.",
    "images": []
  }
]

export default CompaniesList;