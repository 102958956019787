import React, { Component } from "react";
import CompaniesList from "../Companies/CompaniesList";
import Companies from "../Companies/Companies";
import CompanyPage from "../Companies/Company/CompanyPage"
import Row from 'react-bootstrap/Row'
import classes from '../../Containers/App.module.css'

// import { CSSTransition } from 'react-transition-group'

class Investments extends Component {
  state = {
    companies: CompaniesList,
    company: null,
  }

  companySelector = (companyIndex) => {
    this.setState({
      company: this.state.companies[companyIndex],
    })
  }

  backToCompanies = () => this.setState({
    company: null,
  })

  render() {
    let page = <Row>
                <Companies 
                  companies={CompaniesList} 
                  clicked={this.companySelector} 
                />
               </Row>;
    if(this.state.company) {
      page = <CompanyPage 
               company={this.state.company} 
               click={this.backToCompanies} 
             />
    } 
    return (
      <div >
        {/* <h1>Investments</h1> */}
          <div className={classes.contentDiv}>
            {page}
          </div>
      </div>
    );
  }
}
 
export default Investments;