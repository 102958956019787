import React from 'react';
// import classes from '../Containers/App.module.css';
import { Navbar } from 'react-bootstrap';
import Logo from './logo';

const navBrand = props => {
  // const c = classes.logoImage+' d-inline-block align-top logo-image'
  return (<Navbar.Brand href="/">
            <Logo />
            {/* <img
              alt=""
              src="/logo2.png"
              width="50"
              height="50"
              className={c}
            />
            <div style={{float: 'right'}}><div className={classes.gadin}>GADIN</div><div className={classes.tech}>TECHNOLOGIES LTD.</div></div> */}
          </Navbar.Brand>);
}

export default navBrand;