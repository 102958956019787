import React, { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import classes from './Company.module.css'


const CompanyPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  let mark = '';
  if(props.company.mark) {
    mark = <h3>Mark: {props.company.mark}</h3>
  }
  
  return(
    <div className={classes.CompanyPage} >
      <img src={props.company.logo} className={classes.images} alt={props.company.name} />
      {/* <h1>{props.company.name}</h1> */}
      <p className={classes.description}>{props.company.shortText}</p>
      {mark}
      <div className={classes.description}>{props.company.description}</div>
      <div>
        <FontAwesomeIcon icon={faLink} />
          <a href={props.company.link} 
            target="_blank" rel="noopener noreferrer"
          > {props.company.link}</a> 
      </div>
      <Button variant="secondary" size="sm" onClick={props.click}>Back</Button><br />
    </div>
    
  )
} 
export default CompanyPage;
