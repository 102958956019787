import React from 'react';
// import Connect from '../connect';
import classes from './Footer.module.css';

const footer = () => (
  <div className={classes.Footer}>
    <img src="/logo2.png" alt="footer logo" />
    <span>GADIN TECHNOLOGIES LTD © 2023</span>
  </div>
);

export default footer;
