import React, { Component } from "react";
import { Form, Row, Col, Button } from 'react-bootstrap';
// import fetch from 'fetch';
// import axios from 'axios';
import classes from '../../Containers/App.module.css';
 
class Contact extends Component {
  constructor( props ) {
    super( props );
    this.resetForm = this.resetForm.bind(this);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      description: "",
    }
  }
  postData = (event) => {
    event.preventDefault();
    const data = {
      firstName: event.target[0].value,
      lastName: event.target[1].value,
      email: event.target[2].value,
      text: event.target[3].value,
    }
    console.log(data);
    fetch('https://api.gadin.tech', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    }).then( (response) => {
      return response.json();
    }).then((data) => {
      alert("mail sent");
      this.resetForm();
    });

  }

  resetForm = () => {
    this.contactFormRef.reset();
  };

  render() {
    return (
      <div>
        <h1>Contact</h1>
        <Form className={classes.mainArea} onSubmit={this.postData} ref={(el) => this.contactFormRef = el}>
          <Form.Group as={Row}>
            <Col md="6" sm="1">
              <Form.Control placeholder="First name" ref="firstName" />
            </Col>
            <Col md="6" sm="1" className={classes.topMargin}>
              <Form.Control placeholder="Last name" ref="lastName" />
            </Col>
          </Form.Group>
          <Form.Group as={Row}> 
            <Col>
              <Form.Control type="email" placeholder="Email" ref="email" />
            </Col>
          </Form.Group>
          <Form.Group as={Row}> 
            <Col>
              <Form.Control type="phone" placeholder="Phone Number" ref="phone" />
            </Col>
          </Form.Group>
          <Form.Group as={Row}> 
            <Col>
              <Form.Control as="textarea" placeholder="Description" rows="6" ref="description" />
            </Col>
          </Form.Group>
          <Form.Group as={Row}> 
            <Col>
              <Button variant="secondary" className={classes.footerMargin} type="submit">
                Submit
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </div>
    );
  }
}
 
export default Contact;