import React from 'react';
import classes from '../Containers/App.module.css';

const logo = props => {
  const c = classes.logoImage+' d-inline-block align-top logo-image'
  return (<span>
            <img
              alt="Gadin Logo"
              src="/logo2021.jpg"
            //   width="50"
              height="70"
              className={c}
            />
            {/* <div style={{float: 'right'}}><div className={classes.gadin}>GADIN</div><div className={classes.tech}>TECHNOLOGIES LTD.</div></div> */}
          </span>)
}

export default logo;