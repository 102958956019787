import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
// import classes from './App.module.css'
import NavBrand from '../components/navBrand.js';
// import Connect from '../components/connect';

export const NavigationBar = (props) => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <NavBrand />
      {/* <Connect classToUse={classes.connect} /> */}
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Item>
            <LinkContainer exact to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          {/* <Nav.Item>
            <LinkContainer to="/about">
              <Nav.Link>About</Nav.Link>
            </LinkContainer>
          </Nav.Item> */}
          <Nav.Item>
            <LinkContainer to="/investments">
              <Nav.Link>Investments</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          {/* <Nav.Item>
            <LinkContainer exact to="/contact">
              <Nav.Link>Contact</Nav.Link>
            </LinkContainer>
          </Nav.Item> */}
          {/* <Nav.Item>
            <LinkContainer exact to="/tali">
              <Nav.Link>Tali</Nav.Link>
            </LinkContainer>
          </Nav.Item> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
