import React from "react";
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import classes from './Company.module.css'

const company = (props) => {
  const { compKey, logo } = props.company;
  const length = props.length;
  const index = props.index;
  let sm = 4;
  if(length % 3 === 1 && index === length - 1) {
    sm = "12"
  } 

  return (
      <Col sm={sm} >
        <Card className={classes.card} onClick={props.click}>
          <Card.Body>
            <Card.Img variant="top" src={logo} className={classes.images} />
            {compKey === 'SmartMirror' 
              ? <span className={classes.smartmirror}>{compKey}</span> 
              : ''} 
            <Card.Text>
              
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    );
}
 
export default company;