import React, { Component } from "react";

class Home extends Component {
  render() {
    return (
      <div>
        <h1>
        {/* <img
              alt=""
              src="/logo.png"
              width="60"
              height="60"
              style={{ paddingRight: '5px'}}
            /> */}
            About
        </h1>
        <p>Gadin Technologies is a family owned private company formed in Israel at 1999. The company is both an investment arm that specializes in early stage technology investments and a consultancy bureau focusing on crisis management.</p>
        <p>In recent years Tali took her passion to design and added the activity of interior design to the company.</p>
        <p>Gadin early stage seed investments in Technology created, until today, companies in the accumulated value of above 2BL USD. Most are Israel based technology companies, but the company invested also in US, European and Australian companies.</p>
        <p>On the business consulting side, the company expertise in crisis management and negotiations. Based on decades of business experience and a body of experts, Gadin provides its customers with a unique direct crisis management, managing the situation directly.</p>
      </div>
    );
  }
}

export default Home;